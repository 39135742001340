
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { ClientConfigService } from 'src/app/member/common/client-config.service';
import { SecureMessageDto, EmailDto, MessageRequestDto, FilterDto, SecureMessageListingDto,
     MessageConversionListingDto, UpdateSecureMessageDto, UpdateAgentReadDateDto,
      CreateOrUpdateSecureMessageDto, DocumentCategoryDto, FullfillmentDto, SaveFullfillmentRequestDto, TemplateDefinitionDto,
       saveMemberDocumentRequestDto, TemplateResolutionRequestDto, TemplateResolutionResponseDto } from './secure-message-section.dtos';

@Injectable({
    providedIn: 'root'
  })
export class SecureMessageSectionService {

    private messageUrl: string = '/Message';
    private sendEmailUrl: string = '/sendEmail?memberId=##memberId##&emailId=##emailId##';
    private uploadAttachmentUrl: string = '/MemberMessageAttachment/UploadAttachment';    
    private downloadAttachmentUrl: string = '/MemberMessageAttachment/DownloadAttachment';   
    private viewMessageUrl: string = '/ViewMessage';
    private updateMessageUrl: string = '/CreateOrUpdateMessage';
    private updateAgentReadUrl: string = '/UpdateReadDate';  
    private getMemberEmailsUrl: string ='/MemberEmailAddress/MemberEmailAddress?MemberId=##memberId##&sourceType=##sourceType##';
    private resendAttachmentUrl: string = '/MemberMessageAttachment/ResendAttachment';
    private getDocumentCategoryUrl: string = '/DocumentCategory';
    private getFullfillmentByCategoryUrl: string = '/Fullfillments';
    private saveFullfillmentUrl: string = '/SaveFullfillment';
    private saveMemberDocumentUrl : string = '/SaveMemberDocuments'
    private downloadFulfillmentDocumentUrl: string='/DownloadFulfillment';
    private getTemplateDefinitionUrl: string = '/TemplateDefinition/allTemplateDefinitions?clientId=##clientId##';
    private resolveTemplateDefinitionUrl: string = '/TemplateDefinition/ResolveTemplate';

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, 
        private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig,
        private ClientConfigService: ClientConfigService) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    public getSecureMessages(memberId: string): Observable<SecureMessageListingDto[]> {
    
        let url = this._appConfig.memberManagerApiBaseUrl + '/member/' + memberId + this.messageUrl 
        return this._http.get<SecureMessageListingDto[]>(url, {headers: this.headers});
    }

    public getViewConversationMessages(messageId: string): Observable<MessageConversionListingDto[]> {
    
        let url = this._appConfig.memberManagerApiBaseUrl + '/member/' + messageId + this.viewMessageUrl 
        return this._http.get<MessageConversionListingDto[]>(url, {headers: this.headers});
    }

    public saveSectionData(formToSave: CreateOrUpdateSecureMessageDto): Observable<string> {

        let url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.updateMessageUrl);

        return this._http.post<any>(url, formToSave, {headers: this.headers});
    }
    
    public updateAgentReadDate(formToSave: UpdateAgentReadDateDto): Observable<string> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.updateAgentReadUrl);

        return this._http.post<string>(url, formToSave, {headers: this.headers});
    }

    public sendReply(formToSave: CreateOrUpdateSecureMessageDto): Observable<string> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.updateMessageUrl);

        return this._http.post<any>(url, formToSave, {headers: this.headers});
    }

    public sendEmail(memberId:string,emailId:string): Observable<string> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.sendEmailUrl)
        .replace("##memberId##", memberId).replace("##emailId##", emailId);

        return this._http.post<any>(url, null, {headers: this.headers});
    }
    
    public uploadAttachmentFiles(body: FormData, messageId:string, memberId:string) : Observable<any> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.uploadAttachmentUrl + '/'+ messageId + '/'+ memberId);
        return this._http.post<any>(url, body, {headers: this.headers});
    }
    
    public resendAttachmentFiles(oldMessageId: string, messageId:string, memberId:string) : Observable<any> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.resendAttachmentUrl + '/' + oldMessageId + '/'+ messageId + '/'+ memberId);
        return this._http.get<any>(url, {headers: this.headers});
    }

    public downLoadAttachmentFile(messageId:string, memberId:string, fileName:string) : Observable<any> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.downloadAttachmentUrl + '/'+ messageId + '/'+ memberId + '?fileName=' + fileName);
        return this._http.get<any>(url, {headers: this.headers, observe: 'body', responseType: 'blob' as 'json'});
    }

    private getMemberMessageRequest(memberId: string): MessageRequestDto {

        let body = new MessageRequestDto();
        
        let filters: FilterDto[] = [];
        let filter = new FilterDto();

        filter.operator = 'equals';
        filter.property = 'MemberId';
        filter.value = memberId;

        filters.push(filter);

        body.pageNumber = 1;
        body.filters = filters;
        body.pageSize = 1000;

        return body;

    }

    public getDropdownValues(memberId: string): Observable<EmailDto[]> {
        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberEmailsUrl)
        .replace("##memberId##", memberId).replace(/##sourceType##/g, Constant.memberSource)

        return this._http.get<EmailDto[]>(url, {headers: this.headers});
    }

    public getDocumentCategories(): Observable<DocumentCategoryDto[]> {
    
        let url = this._appConfig.memberManagerApiBaseUrl + '/member' + this.getDocumentCategoryUrl; 
        return this._http.get<DocumentCategoryDto[]>(url, {headers: this.headers});
    }

    public getFullfillmentByCategory(categoryId: string, descSearchText: string): Observable<FullfillmentDto[]> {

        descSearchText = descSearchText ? descSearchText : null;
        categoryId = categoryId ? categoryId : null;
        const url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.getFullfillmentByCategoryUrl + '?categoryId='+categoryId+'&&descSearchText=' + descSearchText); 
        return this._http.get<FullfillmentDto[]>(url, {headers: this.headers});
    }

    public saveFullfillment(saveFullfillmentRequest: SaveFullfillmentRequestDto ): Observable<any> {

        const url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.saveFullfillmentUrl); 
        return this._http.post<any>(url, saveFullfillmentRequest, {headers: this.headers});
    }

    public saveMemberDocument(saveMemberDocumentRequest : saveMemberDocumentRequestDto) : Observable<any> {

        const url = (this._appConfig.memberManagerApiBaseUrl + '/member' + this.saveMemberDocumentUrl); 
        return this._http.post<any>(url, saveMemberDocumentRequest, {headers: this.headers});
    }

    public downloadFulfillmentDocument (memberId:string, documentId:string) : Observable<any> {

        let url = `${this._appConfig.memberManagerApiBaseUrl}${'/member'}${this.downloadFulfillmentDocumentUrl}${'/'}${memberId}${'/'}${documentId}`; 
        return this._http.get(url, {responseType: 'blob'});
    }

    public getTemplateDefinitions(clientId: string): Observable<TemplateDefinitionDto[]> {
    
        let url = this._appConfig.templateServiceApiBaseUrl + this.getTemplateDefinitionUrl
            .replace('##clientId##', clientId);
        return this._http.get<TemplateDefinitionDto[]>(url, {headers: this.headers});
    }

    public resolveTemplateDefinition(requestModel: TemplateResolutionRequestDto): Observable<TemplateResolutionResponseDto> {

        let url: string = this._appConfig.templateServiceApiBaseUrl + this.resolveTemplateDefinitionUrl ;
        return this._http.post<TemplateResolutionResponseDto>(url, requestModel, {headers: this.headers});
    }

    public getConfig(key: string, clientId: string): Promise<string> {

        return this.ClientConfigService.getConfig(key, clientId).toPromise()
            .then((response: any) => {                        
                return Promise.resolve(response);
        });
    }
}
