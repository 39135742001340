import { DocumentDto } from "../documents-section/documents-section.dtos";


export class SecureMessageDto {
    Id:string;
    subject: string;
    memberFk: string;
    senderType: string;    
    sentEmail:string;
    rowSource: string;
    conversations: ConversationMessageDto ;
}

export class CreateOrUpdateSecureMessageDto {
    conversationId:string;
    memberId: string;
    subject: string;  
    text: string;
    from: string;
    to: string;
    agentReadDate?:string;
    memberReadDate?:string;
    rowSource: string;
    templateId: string;
    
}

export class UpdateSecureMessageDto {
    messageId:string;
    subject: string;
    memberFk: string;
    senderType: string;    
    sentEmail:string;
    rowSource: string;
    conversations: ConversationMessageDto ;
}

export class EmailDto {
    emailAddress: string;
    emailId?: string;
    isPrimary?: boolean;
    isActive?: boolean = true;
    edit?: boolean = false;
    add?: boolean = false;
    rowSource: string;
  }

export class SecureMessageListingDto {
    messageId:string;
    conversationId:string;
    memberId: string;
    subject: string;  
    text: string;
    from: string;
    to: string;
    agentReadDate?:string;
    memberReadDate?:string;
    attachmentCount:number = 0;
    fulfillmentCount:number = 0;
    documentCount:number=0;
    attachmentFulfillmentDocumentCount:number = 0;
    isAgentUnRead: boolean;
    isMemberUnRead: boolean;
    rowSource: string;
    createdDate: string;
    deletedDate: string
}

export class AttachmentDto  {
    messageId:string;
    attachmentId:string;
    fileLocation:string;
    fileName:string;
    rowSource: string;
    createdDate: string;
    deletedDate: string;
}

export class SecureMessageListDto {
    data: SecureMessageDto[] = [];
    count: number;
}

export class SecureMessageAttachmentDto {
    messageSubject: string;
    fileLocation: string;
    fileName: string;
    messageTo: string;
    messageFrom: string;
    memberID: string;
    attachmentID:string;
}

export class MessageRequestDto {
    pageSize: number;
    filters: FilterDto[];
    pageNumber: number
}

export class FilterDto {
    property: string;
    value: string;
    operator: string;
}


export class MessageConversionListingDto { 
    subject: string;
    from: string;
    to: string;
    text: string;
    createdDate: string;
    attachmentId: string;
    fileLocation: string;
    fileName: string;
    isReplyFromPolicyHolder: boolean;
    id: string;
    messageFk: string;
    memberReadDate: string;
    agentReadDate: string;
    rowSource: string;
    deletedDate: string;
    templateId: string;
    attachments: AttachmentDto[] = [];
    fullfillments: FullfillmentDto[] = [];
    documents: DocumentDto[]=[];
}

export class ConversationMessageDto {
    id?:string;
    text: string;
    from: string;
    to: string;
    createdDate: string;
    agentReadDate?:string;
    memberReadDate?:string;
    rowSource: string;
    messageFk: string;
    attachmentId?:string;
    fileLocation?:string;
    fileName?:string;
}

export class  UpdateAgentReadDateDto {
    conversationId: string;
    agentReadDate: string;
    memberReadDate?:string
}

export class  FullfillmentDto {
    id: string;
    documentName: string;
    documentDescription: string;
    isSelected?: boolean = false;
    documentCode: string;
    documentTypeId: string;
    FileTypeId: string;
    DocumentVersion: string;
    Url: string;
}

export class  DocumentCategoryDto {
    id: string;
    name: string;
}

export class  SaveFullfillmentRequestDto {
    memberMessageId: string;
    rowSource: string;
    documentDefinitionIds: Array<string>;
}

export class saveMemberDocumentRequestDto{
    memberMessageId: string;
    memberDocumentIds: Array<string>;
    rowSource: string;

}

export class ViewConversionFullfillmentDto {
    id: string;
    documentName: string;
    documentDescription: string;
    rowSource: string;
}

export class  TemplateDefinitionDto {
    id: string;
    templateName: string;
    templateDescription: string;
    templateBody: string;
    templateCategoryId: string;
    templateTypeId: string;
    allowUserEntry:boolean;
}

export class TemplateResolutionRequestDto {
    public templateDefinitionId: string;
    public asFullHtmlDocument: boolean;
    public inputParams: TemplateInputParamDto[] = [];
}

export class TemplateInputParamDto {
    public queryParamName: string;
    public queryParamValue: string;
};

export class TemplateResolutionResponseDto {
    public templateFactDefinitionId: string;
    public resolvedTemplate: string;
}
