<section class="vh-100">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9 col-lg-6 px-0 col-xl-7">
        <div class="image">
          <img src="../assets/images/background_Image_5.png" alt="Login image" class="w-100 vh-100"
            style="object-fit: cover; object-position: left;">
          <div class="hero-section">
            <p class="welcome text-center">Welcome<p>
          </div>
          <div class="hero-info">
            <p> to the Agent Portal </p>
          </div>
        </div>
      </div>
      <div class="login-details col-md-8 col-lg-6 col-xl-5 mt-4">
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"  novalidate>
          <div class="lead d-flex flex-row align-items-center justify-content-center text-center ">
            <p class="mb-2 me-3 ">Log in to your account</p>
          </div>

          <div class="text-center" *ngIf="isError">
            <h6 style="color: red;" class="error">The username or password you entered does not match our records. Please try again.</h6> 
          </div>
          <!-- Email input -->
          <div class="form-outline text-center mb-4">
            <img src="../../assets/images/email.png"  class="icons" alt="">
            <!-- <input type="email" id="emailAddress" name="emailAddress"  class="form-control form-control-lg" placeholder="hello@example.com"  required emailAddress /> -->
            <input type="email" name="emailAddress" class="form-control form-control-lg"  placeholder="Enter Email Address"
             [(ngModel)]="user.emailAddress" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"  required email>
            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors?.['required']">Email is required</div>
              <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
            </div>
          </div>
          <div class="form-outline text-center mb-4">
            <img src="../../assets/images/shield.png"  class="icons" alt=""> 
            <span (click)="hideShowPass()" class="eyeicons fa {{eyeIcon}} fa-lg"></span>
              <!-- <input type="password" id="password" name = "password" class="form-control form-control-lg" placeholder="password" ngModel /> -->
              <input type="{{type}}"  name="password" class="form-control form-control-lg" placeholder="Enter Password"
              [(ngModel)]="user.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required minlength="6">
              <!-- <app-password-strength [passwordToCheck]="f.value.password"></app-password-strength> -->
              <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                  <div *ngIf="password.errors?.['required']">Password is required</div>
                  <div *ngIf="password.errors?.['minlength']">Password must be at least 6 characters</div>
              </div>
          </div>
         
          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2" type="checkbox" value="" id="isSaveMe" [(ngModel)]="isSaveMe" name="isSaveMe"/>
              <label class="form-check-label text-muted" for="form2Example3">
                <span>save password</span>
              </label>
            </div>
            <a href="#!" class="text-body text-muted" routerLink="/sign-up-forgot-password"> <span> Forgot password?</span></a>
          </div>
          <div class="text-center pt-1 mt-4 mb-4 pb-1">
            <button type="submit" class="btn btn-lg btn-block log-in">Login</button>
          </div>
          <div class="divider d-flex align-items-center my-4">
            <p class="text-center fw-bold mx-3 mb-0"> <span> Or sign up</span></p>
          </div>
          <!-- <div class="d-flex flex-row align-items-center justify-content-center">
            <button type="button" class="btn btn-floating mx-1">
              <img src="../assets/images/google.png" width="50px" alt="">
            </button>

            <button type="button" class="btn btn-floating mx-1">
              <img src="../assets/images/facebook.png" width="50px" alt="">
            </button>

            <button type="button" class="btn  btn-floating mx-1">
              <img src="../assets/images/twitter.png" width="50px" alt="">
            </button>
          </div> -->
          <p class="mt-2 pt-1"><span>First time user?</span>
          <button type="button" class="sign-up btn btn-lg btn-block mt-2" routerLink="/sign-up">Sign up</button>

        </form>
      </div>
    </div>
  </div>
</section>

