<div>
    <nav class="navbar navbar-color">
        <div class="navbar-title">Member Manager</div>
    </nav>

    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <div class="wrapper d-flex align-items-stretch">
        <nav id="sidebar" class="active">
            <ul class="list-unstyled components ">
                <li>
                    <a><img src="../../assets/images/Image_10.png" alt=""></a>
                </li>
                <li>
                    <a><img src="../../assets/images/profile_Icon.png" alt=""></a>
                </li>
            </ul>
        </nav>

        <div id="content" class="p-4 p-md-4 col-md-8 col-lg-6 col-xl " style="padding-bottom: 0!important; min-height: unset;">
            
            <header-section 
                [memberId]="memberId"
                (memberIsDeceasedEvent) = setMemberIsDeceased($event)
                (headerIsLoadedEvent) = setHeaderIsLoaded($event)
                (headerIsBenefitsSectionAvailableEvent) = setBenefitsSectionAvailable($event)
                (setClientIdEvent)="setClientIdEvent($event)" >
            </header-section>

            <hr>

            <div *ngIf="memberIsDeceased" style="display: flex;">
                <span class="deceased">
                    Member is deceased
                </span>
            </div>

            <!-- Contact Information -->            
            <div class="accordion" id="ContactsSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Contacts">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('contact')" data-bs-toggle="collapse"
                            data-bs-target="#Contacts-body" aria-expanded="true" aria-controls="Contacts-body">
                            <h3 class="sub-headings"> 
                                <img [src]="sectionContactExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Contact Information
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Contacts-body" class="accordion-collapse collapse" aria-labelledby="Contacts" data-bs-parent="Contacts">
                            <div class="accordion-body">
                                <contact-section 
                                    [memberId]="memberId" 
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)"
                                    (memberIsDeceasedEvent)="memberIsDeceasedEvent($event)">
                                </contact-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Caregiver List -->
            <div class="accordion" id="CaregiversSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Caregivers">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('caregiver')" data-bs-toggle="collapse"
                            data-bs-target="#Caregivers-body" aria-expanded="true" aria-controls="Caregivers-body">
                            <h3 class="sub-headings"> 
                                <img [src]="sectionCaregiverExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Caregiver Information
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Caregivers-body" class="accordion-collapse collapse" aria-labelledby="Caregivers" data-bs-parent="Caregivers">
                            <div class="accordion-body">
                                <caregiver-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </caregiver-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Secure Messages -->
            <div class="accordion" id="SecureMessagesSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="SecureMessages">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('secureMessage')" data-bs-toggle="collapse"
                            data-bs-target="#SecureMessages-body" aria-expanded="true" aria-controls="SecureMessages-body">
                            <h3 class="sub-headings"> 
                                <img [src]="sectionSecureMessagesExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Secure Messages
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="SecureMessages-body" class="accordion-collapse collapse" aria-labelledby="SecureMessages" data-bs-parent="SecureMessages">
                            <div class="accordion-body">
                                <secure-message-section 
                                    [memberId]="memberId"
                                    [clientId]="clientId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </secure-message-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            
            <!-- Population -->
            <div class="accordion" id="PopulationsSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Populations">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('population')" data-bs-toggle="collapse"
                            data-bs-target="#Populations-body" aria-expanded="true" aria-controls="Populations-body">
                            <h3 class="sub-headings"> 
                                <img [src]="sectionPopulationExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Population
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Populations-body" class="accordion-collapse collapse" aria-labelledby="Populations" data-bs-parent="Populations">
                            <div class="accordion-body">
                                <population-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </population-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- CarePlan -->
            <div class="accordion" id="CareplanSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Careplan">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('careplan')" data-bs-toggle="collapse"
                            data-bs-target="#Careplan-body" aria-expanded="true" aria-controls="Careplan-body">
                            <h3 class="sub-headings"> 
                                <img [src]="sectionCareplanExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Care Plan
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Careplan-body" class="accordion-collapse collapse" aria-labelledby="Careplan" data-bs-parent="Careplan">
                            <div class="accordion-body">
                                <careplan-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </careplan-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Assessment -->
            <div class="accordion" id="AssessmentsSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Assessments">
                        <button #assessmentButtonSection class="accordion-button accordion-button1" type="button" (click)="openSection('assessment')" data-bs-toggle="collapse"
                            data-bs-target="#Assessments-body" aria-expanded="true" aria-controls="Assessments-body">
                            <h3 class="sub-headings">
                                 <img [src]="sectionAssessmentExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Assessment
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Assessments-body" class="accordion-collapse collapse" aria-labelledby="Assessments" data-bs-parent="Assessments">
                            <div class="accordion-body">
                                <assessment-section 
                                    [memberId]="memberId" 
                                    [memberAtomId]="memberAtomId"
                                    [memberIsDeceased] = "memberIsDeceased"
                                    [memberAssessmentId]="memberAssessmentId" 
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)"
                                    (assessmentCompletedEvent) = "assessmentCompletedEvent($event)">
                                </assessment-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Website Visits -->
            <div class="accordion" id="WebsitesSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Websites">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('websiteVisits')" data-bs-toggle="collapse"
                            data-bs-target="#Websites-body" aria-expanded="true" aria-controls="Websites-body">
                            <h3 class="sub-headings">
                                 <img [src]="sectionWebsiteExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Website Visits
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Websites-body" class="accordion-collapse collapse" aria-labelledby="Websites" data-bs-parent="Websites">
                            <div class="accordion-body">
                                <website-visit-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </website-visit-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Member Tasks Management -->
            <div class="accordion" id="TasksSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Tasks">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('tasks')" data-bs-toggle="collapse"
                            data-bs-target="#Tasks-body" aria-expanded="true" aria-controls="Tasks-body">
                            <h3 class="sub-headings">
                                 <img [src]="sectionTasksExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                Member Tasks Management
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Tasks-body" class="accordion-collapse collapse" aria-labelledby="Tasks" data-bs-parent="Tasks">
                            <div class="accordion-body">
                                <task-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </task-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Documents -->
            <div class="accordion" id="DocumentsSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Documents">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('documents')" data-bs-toggle="collapse"
                            data-bs-target="#Documents-body" aria-expanded="true" aria-controls="Documents-body">
                            <h3 class="sub-headings">
                                 <img [src]="sectionDocumentsExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                 Documents
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Documents-body" class="accordion-collapse collapse" aria-labelledby="Documents" data-bs-parent="Documents">
                            <div class="accordion-body">
                                <documents-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </documents-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Benefits -->
            <div *ngIf="benefitsSectionIsAvailable" class="accordion" id="BenefitsSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Benefits">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('benefits')" data-bs-toggle="collapse"
                            data-bs-target="#Benefits-body" aria-expanded="true" aria-controls="Benefits-body">
                            <h3 class="sub-headings">
                                 <img [src]="sectionBenefitsExpanded ? '../../assets/images/minus_icon.png' : '../../assets/images/plus_Icon.png'" alt="" class="sub-heading-pad">
                                 Benefits
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color"  >
                        <div id="Benefits-body" class="accordion-collapse collapse" aria-labelledby="Benefits" data-bs-parent="Benefits">
                            <div class="accordion-body">
                                <benefits-section 
                                    [memberId]="memberId"
                                    (sectionLoadedEvent)="sectionLoadedEvent($event)">
                                </benefits-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Outreach History -->
            <!-- <div class="accordion" id="OutreachSection">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="Outreach">
                        <button class="accordion-button accordion-button1" type="button" (click)="openSection('outreach')" data-bs-toggle="collapse"
                            data-bs-target="#Outreach-body" aria-expanded="true" aria-controls="Outreach-body">
                            <h3 class="sub-headings"> <img src="../../assets/images/minus_icon.png" alt="" class="sub-heading-pad">
                                Outreach History
                            </h3>
                        </button>
                    </h2>
                    <div class="table-bg-color">
                        <div id="Outreach-body" class="accordion-collapse collapse" aria-labelledby="Outreach" data-bs-parent="Outreach">
                            <div class="accordion-body">
                                <outreach-section [memberId]="memberId"></outreach-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="row" style="padding-top: 1em">
                <div class="flex-parent jc-center" style="padding-bottom: 1em;">
                    <button class="btn button-1 page-bottom-button-width" type="button" (click)="finalSubmit()">Submit All</button>
                    <button class="btn button-1 page-bottom-button-width" type="button" (click)="reloadPage()" style="margin-left: 1em;">Reload</button>
                </div>
            </div>
            <div class="flex-parent jc-center">
                <button class="btn button-1 page-bottom-button-width jump-button" type="button" (click)="onScrollToTop()">Jump to Top</button>
            </div>
        </div>
    </div>

</div>